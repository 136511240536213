export const MAIN_BUSINESS = "MAIN_BUSINESS";
export const DOC_DRIVER = "DOC_DRIVER";
export const DOC_LOADING = "DOC_LOADING";
export const DOC_CARRIER = "DOC_CARRIER";
export const DOC_DRIVERS = "DOC_DRIVERS";
export const DOC_CARRIERS = "DOC_CARRIERS";
export const DOC_TRAILERS = "DOC_TRAILERS";
export const DOC_TRAILER = "DOC_TRAILER";
export const DOC_BUSINESS = "DOC_BUSINESS";
export const DOC_BUSINESS_ALIAS = "DOC_BUSINESS_ALIAS";
